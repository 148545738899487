import React from 'react';
import { Grid, Typography, Paper, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import useStyles from './styles';
import api from '../../../services/api';
function Dashboard() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});

  async function getData() {
    try {
      setLoading(true);

      const response = await api.get('/super/count');

      setData(response.data);

      setLoading(false);
    } catch (error) {
      toast.error('Ocorreu algum erro, tente novamente mais tarde.');
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Grid container spacing={2}>
      {loading ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={3} sm={4}>
            <Paper className={classes.paper}>
              <Typography className={classes.title}>Tarefas </Typography>

              <Typography className={classes.info}>{data.tasks}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={3} sm={4}>
            <Paper className={classes.paper}>
              <Typography className={classes.title}>
                Tarefas respondidas
              </Typography>

              <Typography className={classes.info}>{data.answers}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={3} sm={4}>
            <Paper className={classes.paper}>
              <Typography className={classes.title}>
                Tarefas corrigidas
              </Typography>

              <Typography className={classes.info}>
                {data.answersCorrected}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={3} sm={4}>
            <Paper className={classes.paper}>
              <Typography className={classes.title}>
                Tarefas recorrigidas
              </Typography>

              <Typography className={classes.info}>
                {data.answersResource}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={3} sm={4}>
            <Paper className={classes.paper}>
              <Typography className={classes.title}>Equipes</Typography>

              <Typography className={classes.info}>{data.teams}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={3} sm={4}>
            <Paper className={classes.paper}>
              <Typography className={classes.title}>Usuarios</Typography>

              <Typography className={classes.info}>{data.users}</Typography>
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Dashboard;
