import React, { useState } from "react";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { Delete, Edit, RemoveRedEye } from "@material-ui/icons";
import { addHours } from "date-fns";

import history from "../../../../../services/history";
import Modal from "../Modal";

export const Task = ({ task, deleteTask }) => {
  const [open, setOpen] = useState(false);

  return (
    <TableRow key={task._id}>
      <TableCell component="th" scope="row">
        {task.number}
      </TableCell>
      <TableCell align="center">{task.title}</TableCell>
      <TableCell align="center">{task.type}</TableCell>
      <TableCell align="center">{task.count}</TableCell>
      <TableCell align="right">
        <IconButton
          onClick={() =>
            history.push("/task/create", {
              edit: true,
              data: {
                ...task,
                date: addHours(new Date(task.date), 3),
              },
            })
          }
        >
          <Edit />
        </IconButton>

        <IconButton onClick={() => setOpen(true)}>
          <RemoveRedEye />
        </IconButton>

        {/*    <IconButton onClick={() => deleteTask(task._id)}>
          <Delete />
        </IconButton> */}
      </TableCell>

      <Modal
        task={task}
        open={open}
        close={() => setOpen(false)}
        att={() => {}}
      />
    </TableRow>
  );
};
