/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import { Form } from "@unform/web";
import Input from "../../../../components/Input";
import { Add, Delete, Edit } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";
import { toast } from "react-toastify";
import history from "../../../../services/history";
import api from "../../../../services/api";
import validator from "./validator";
import Modal from "./ModalAdd";
import ModalEdit from "./Modallevel";
import * as Yup from "yup";

function Create({ match }) {
  const formRef = React.useRef();
  const id = match.params.id;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState("Geral");
  const [loadingGet, setLoadingGet] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openType, setOpenType] = React.useState(false);
  const [user, setUser] = React.useState(null);

  function selectUser(user) {
    setUser(user);
    setOpenType(true);
  }

  function close() {
    setOpenType(false);
    setUser(null);
  }

  async function submit(e) {
    formRef.current.setErrors({});

    try {
      setLoading(true);

      await validator(e);

      await api.put(`/team`, {
        teamId: id,
        ...e,
        type,
      });

      toast.success("Equipe Atualizada com sucesso");
      setLoading(false);
      history.push("/team");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        console.log(err);

        toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      }

      setLoading(false);
    }
  }

  async function getTeam() {
    try {
      setLoadingGet(true);

      const response = await api.get(`/team/${id}`);

      setType(response.data.type);
      setUsers(response.data.users);

      if (formRef.current) {
        formRef.current.setData(response.data);
      }

      setLoadingGet(false);
    } catch (error) {
      console.log(error);

      history.goBack();
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      setLoadingGet(false);
    }
  }

  React.useEffect(() => {
    getTeam();
  }, []);

  async function exclueUser(user) {
    try {
      setLoading(true);
      await api.post(`/team/quit-force`, {
        userId: user._id,
      });

      toast.success("Usuario excluido da equipe com sucesso.");

      getTeam();

      setLoading(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");

      setLoading(false);
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Editar Equipe</Typography>
        </Grid>
        <Grid item xs={12}>
          <Form ref={formRef} onSubmit={submit} initialData={{}}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input name="name" label="Nome da equipe" />
              </Grid>
              <Grid item xs={6}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={"Geral"}>Geral</MenuItem>
                  <MenuItem value={"Lobo"}>Lobinho</MenuItem>
                  <MenuItem value={"Jota"}>Jota</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="description"
                  label="Descrição da equipe"
                  rowsMax={20}
                  multiline
                />
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="h5">Usuarios da equipe</Typography>
                    </Grid>

                    <Grid item>
                      <IconButton onClick={() => setOpen(true)}>
                        <Add />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ width: "100%" }}>
                <Grid container spacing={2} style={{ width: "100%" }}>
                  {users.map((user) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Paper
                        style={{ padding: 10, borderRadius: 5, width: "100%" }}
                      >
                        <Grid container spacing={2} style={{ width: "100%" }}>
                          <Grid item xs={12}>
                            <Typography noWrap>{user.name}</Typography>
                            <Typography>{user.team.level}</Typography>
                            <Typography>{user.register}</Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item>
                                <IconButton onClick={() => selectUser(user)}>
                                  <Edit />
                                </IconButton>
                              </Grid>

                              <Grid item>
                                <IconButton onClick={() => exclueUser(user)}>
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {loading || loadingGet ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <Button type="submit" variant="contained" color="primary">
                    Editar Equipe
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>

      <Modal
        open={open}
        close={() => setOpen(false)}
        att={() => getTeam()}
        team={id}
      />

      <ModalEdit
        open={openType}
        close={() => close(false)}
        att={() => getTeam()}
        team={id}
        user={user}
      />
    </Paper>
  );
}

export default Create;
